import React from 'react';
import clsx from 'clsx';
import { Navigate, Outlet } from "react-router-dom";
import Header from '../header/header';
import styles from './header-layout.module.scss';
import { useAuth } from '@/app/providers/auth-provider';
import Loader from '../loader/loader';

const HeaderLayout = () => {
    const { session, isLoading } = useAuth();
    if (isLoading) return <Loader />
    if (!session) return <Navigate to={"/login"} replace />

    return (
        <React.Fragment>
            <Header />
            <div className={clsx(styles.bodyLayout)}>
                <Outlet />
            </div>
        </React.Fragment>
    )
}

export default HeaderLayout