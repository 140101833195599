import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router";

import ProtectedRoute from "./providers/protected-route-provider";
import { PERMISSION } from "@/shared/types/permission.type";
import SuspenseLoader from "@/shared/ui/suspense-loader/suspense-loader";
import HeaderLayout from "@/shared/ui/layout/header-layout";
import PublicRoute from "./providers/public-route-provider";

const LazyLoader = (Component: any) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// pages
const Login = LazyLoader(lazy(() => import('@/pages/auth/login')));
const NotFoundError = LazyLoader(lazy(() => import("@/pages/error/not-found/not-found-error")));
const UnauthorizedError = LazyLoader(lazy(() => import("@/pages/error/unauthorized/unauthorized-error")));
const Dashboard = LazyLoader(lazy(() => import("@/pages/dashboard/dashboard")));
const Question = LazyLoader(lazy(() => import("@/pages/question/question")));
const PreviewQuestion = LazyLoader(lazy(() => import("@/pages/question/preview-question/question-preview")));
const ForgetPassword = LazyLoader(lazy(() => import("@/pages/auth/forget-password")));
const ResetPassword = LazyLoader(lazy(() => import("@/pages/auth/reset-password")));
const ChangePassword = LazyLoader(lazy(() => import("@/pages/auth/change-password")));
const VerifyLoginPin = LazyLoader(lazy(() => import("@/pages/auth/verify-login-pin")));
const TemporaryBlock = LazyLoader(lazy(() => import("@/pages/auth/temporary-block")));
const VerifyForgotPin = LazyLoader(lazy(() => import("@/pages/auth/verify-forgot-pin")));
const SidebarWrapper = LazyLoader(lazy(() => import("@/pages/setting/sidebar-wrapper/sidebar-wrapper")));
const Profile = LazyLoader(lazy(() => import("@/pages/setting/profile")));
const ChangeOldPassword = LazyLoader(lazy(() => import("@/pages/setting/change-password/change-password")));
const VerifyChangePasswordPin = LazyLoader(lazy(() => import("@/pages/auth/verify-change-password-pin")));
const AddQuestion = LazyLoader(lazy(() => import("@/pages/question/add-question/add-question")));
const EditQuestion = LazyLoader(lazy(() => import("@/pages/question/edit-question/edit-question")));
const AddQuestionTranslate = LazyLoader(lazy(() => import("@/pages/question/add-question-translation/add-question-translation")));
const Exam = LazyLoader(lazy(() => import("@/pages/exam/exam")));
const Format = LazyLoader(lazy(() => import("@/pages/format/format")));
const FormatCheatSheet = LazyLoader(lazy(() => import("@/pages/format/format-cheat-sheet/format-cheat-sheet")));
const User = LazyLoader(lazy(() => import("@/pages/user/user")));
const Level = LazyLoader(lazy(() => import("@/pages/level/level")));
const DifficultyLevel = LazyLoader(lazy(() => import("@/pages/difficulty-level/difficulty-level")));
const QuestionCode1 = LazyLoader(lazy(() => import("@/pages/question-code/question-code-1/question-code-1")));
const QuestionCode2 = LazyLoader(lazy(() => import("@/pages/question-code/question-code-2/question-code-2")));
const Content = LazyLoader(lazy(() => import("@/pages/content/content")));
const Subjects = LazyLoader(lazy(() => import('@/pages/content/subject/subject')));
const Topic = LazyLoader(lazy(() => import('@/pages/content/topic/topic')));
const SubTopic = LazyLoader(lazy(() => import('@/pages/content/sub-topic/sub-topic')));
const PrivacyPolicy = LazyLoader(lazy(() => import('@/pages/privacy-policy/privacy-policy')));
const TermsAndConditions = LazyLoader(lazy(() => import('@/pages/terms-and-condition/terms-and-condition')));
const ResetLinkSent = LazyLoader(lazy(() => import("@/pages/auth/reset-link-sent")));

const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <ProtectedRoute permission={PERMISSION.FALLBACK}>
        <>Welcome</>
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <PublicRoute>
        <ForgetPassword />
      </PublicRoute>
    ),
  },
  {
    path: "/reset-link-sent",
    element: (
      <PublicRoute>
        <ResetLinkSent />
      </PublicRoute>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    path: "/change-password",
    element: (
      <PublicRoute>
        <ChangePassword />
      </PublicRoute>
    ),
  },
  {
    path: "/verify-login-pin",
    element: (
      <PublicRoute>
        <VerifyLoginPin />
      </PublicRoute>
    ),
  },
  {
    path: "/verify-change-pin",
    element: (
      <PublicRoute>
        <VerifyChangePasswordPin />
      </PublicRoute>
    ),
  },
  {
    path: '/temporary-block',
    element: <PublicRoute>
      <TemporaryBlock />
    </PublicRoute>
  },
  {
    path: '/verify-forgot-pin',
    element: <PublicRoute>
      <VerifyForgotPin />
    </PublicRoute>
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />
  },
  {
    path: "/403",
    element: <UnauthorizedError />,
  },
  {
    path: "/404",
    element: <NotFoundError />,
  },
  {
    path: "*",
    element: <Navigate to={"/404"} replace />,
  },
  {
    path: "",
    element: <HeaderLayout />,
    children: [
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute
            permission={PERMISSION.VIEW_DASHBOARD}
          >
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "/questions",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_QUESTIONS}>
            <Question />
          </ProtectedRoute>
        ),
      },
      {
        path: "/questions/:id/preview",
        element: (
          <ProtectedRoute permission={PERMISSION.PREVIEW_QUESTIONS}>
            <PreviewQuestion />
          </ProtectedRoute>
        )
      },
      {
        path: "/questions/:id/edit",
        element: (
          <ProtectedRoute permission={PERMISSION.UPDATE_QUESTIONS}>
            <EditQuestion />
          </ProtectedRoute>
        )
      },
      {
        path: "/questions/:id/translation",
        element: (
          <ProtectedRoute permission={PERMISSION.ADD_QUESTIONS_TRANSLATION}>
            <AddQuestionTranslate />
          </ProtectedRoute>
        )
      },
      {
        path: "/questions/add-question",
        element: (
          <ProtectedRoute permission={PERMISSION.CREATE_QUESTIONS}>
            <AddQuestion />
          </ProtectedRoute>
        )
      },
      {
        path: "question-code-1",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_QUESTION_CODE_1}>
            <QuestionCode1 />
          </ProtectedRoute>
        ),
      },
      {
        path: "question-code-2",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_QUESTION_CODE_2}>
            <QuestionCode2 />
          </ProtectedRoute>
        ),
      },
      {
        path: "exams",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_EXAMINATIONS}>
            <Exam />
          </ProtectedRoute>
        ),
      },
      {
        path: "formats",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_FORMATS}>
            <Format />
          </ProtectedRoute>
        ),
      },
      {
        path: "formats/cheat-sheet",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_FORMATS}>
            <FormatCheatSheet />
          </ProtectedRoute>
        ),
      },
      {
        path: "",
        element: <Content />,
        children: [
          {
            path: "subjects",
            element: <ProtectedRoute permission={PERMISSION.VIEW_SUBJECTS}>
              <Subjects />
            </ProtectedRoute>
          },
          {
            path: "topics",
            element: <ProtectedRoute permission={PERMISSION.VIEW_TOPICS}>
              <Topic />
            </ProtectedRoute>
          },
          {
            path: "sub-topics",
            element: <ProtectedRoute permission={PERMISSION.VIEW_SUB_TOPICS}>
              <SubTopic />
            </ProtectedRoute>
          }
        ]
      },
      {
        path: "/users",
        element: <ProtectedRoute permission={PERMISSION.VIEW_USERS}>
          <User />
        </ProtectedRoute>
      },
      {
        path: "/levels",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_LEVELS}>
            <Level />
          </ProtectedRoute>
        ),
      },
      {
        path: "/difficulty-levels",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_DIFFICULTY_LEVELS}>
            <DifficultyLevel />
          </ProtectedRoute>
        ),
      },
      {
        path: "setting",
        element: (
          <ProtectedRoute permission={PERMISSION.VIEW_PROFILE}>
            <SidebarWrapper />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "*",
            element: <Navigate to={"/setting/profile"} replace />,
          },
          {
            path: "profile",
            element: (
              <ProtectedRoute permission={PERMISSION.VIEW_PROFILE}>
                <Profile />
              </ProtectedRoute>
            ),
          },
          {
            path: "change-password",
            element: (
              <ProtectedRoute permission={PERMISSION.VIEW_PROFILE}>
                <ChangeOldPassword />
              </ProtectedRoute>
            ),
          }
        ]
      }
    ],
  },
];

export default routes;
