import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import styles from './unknown-error.module.scss'
import { Button } from 'primereact/button';
import { Helmet } from 'react-helmet-async';

export const UnknownError = (props: FallbackProps) => {
    const { error, resetErrorBoundary } = props;
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>ZENITH | Error</title>
            </Helmet>
            <div className={styles.pageBodyContainer}>
                <div className={styles.pageBody}>

                    <img src={'/assets/unknown.svg'} alt="Unknown" className={styles.errorImage} />

                    <div className={styles.errorTextWrapper}>
                        <span className={styles.errorText}>Something Went Wrong</span>
                        <span className={styles.errorDescription}>{error?.message ?? 'An unexpected error occurred. Please try again later.'}</span>
                    </div>

                    <Button className={styles.goBackButton} onClick={resetErrorBoundary} > BACK TO HOME </Button>

                </div>
            </div>
        </div>
    )
}
