import React from 'react';
import { Navigate, PathRouteProps } from 'react-router-dom';

import { useAuth } from './auth-provider';
import { PERMISSION } from '@/shared/types/permission.type';
import Loader from '@/shared/ui/loader/loader';
import { hasPermission, ROLE_PERMISSIONS } from '@/shared/lib/access-control';

interface ProtectedRouteProps extends PathRouteProps {
  permission: PERMISSION;
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  permission,
  children
}) => {
  const { session, isLoading, isError } = useAuth();
  if (isLoading) return <Loader />
  if (!session) return <Navigate to={"/login"} replace />

  const role = session.role;
  const fallbackRoute = ROLE_PERMISSIONS[role]?.defaultRoute;
  if (permission === PERMISSION.FALLBACK) return <Navigate to={fallbackRoute} replace />

  const userHasPermission = hasPermission(role, permission);
  if (userHasPermission) return <>{children}</>;
  else return <Navigate to={"/403"} replace />
};

export default ProtectedRoute;
