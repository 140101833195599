import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { logoutUser } from "../api/logout.api";

export const useLogOut = () => {
  return useMutation({
    mutationFn: () => logoutUser(),
    onSuccess: (res) => {
      toast.success(res?.message);
      window.location.href = "/";
    },
    onError: (err: unknown) => {
      if (err instanceof AxiosError) {
        const error = err.response;
        const errorMessage = error?.data?.message || err.message;
        toast.error(errorMessage || "Failed to logout");
      }
    },
  });
};
