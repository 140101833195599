import clsx from 'clsx';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from './header.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useLogOut } from '@/features/auth/logout/model/logout.mutation';
import { Skeleton } from 'primereact/skeleton';
import { useAuth } from '@/app/providers/auth-provider';
import { USER_ROLE_LABELS } from '@/entities/user/lib/user-role';

const Header = () => {
  const navigate = useNavigate();
  const logOutMutation = useLogOut();
  const { session: userDetails, isLoading } = useAuth();

  return (
    <Navbar className={clsx(styles.navbar, 'navbar-header')}>
      <Navbar.Brand className='cursor-pointer' onClick={() => navigate('/')}>
        <div className='d-lg-block d-none'>
          <img
            alt=""
            src="/assets/desktop-logo.svg"
            height="35"
            className="d-inline-block align-top"
          />
        </div>
        <div className='d-lg-none d-block'>
          <img
            alt=""
            src="/assets/mobile-logo.svg"
            height="35"
            className="d-inline-block align-top"
          />
        </div>
        {' '}
      </Navbar.Brand>
      <Nav
        className="ms-auto my-2 my-lg-0 d-flex align-items-center"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
        <Link to={"/questions"} className={clsx('me-lg-3 me-2', styles.quickQuestionAddButton)}>
          <span className='d-lg-block d-none text-uppercase'>Question Management</span>
          <span className='d-lg-none d-block text-uppercase'>Questions</span>
        </Link>


        <NavDropdown
          title={
            <div className='d-flex align-items-center gap-2'>
              <div className={clsx(styles.profileAvatar)}>
                {isLoading ?
                  <Skeleton color='#E9F5E6' className={styles.inputSkelton} width="100%" height="40px"></Skeleton>
                  :
                  userDetails?.fullName?.charAt(0)
                }
              </div>
              <div className={clsx(styles.profileName, 'd-lg-block d-none')}>
                <span className='font-14 mb-0 ellipsis-width' style={{ maxWidth: '120px' }}>
                  {isLoading ?
                    <Skeleton style={{ backgroundColor: "#E9F5E6" }} className={styles.inputSkelton} width="80px" height="40px"></Skeleton>
                    :
                    userDetails?.fullName
                  }
                </span>
                <small className='d-block' style={{ fontSize: '10px', lineHeight: '10px', color: '#777' }}>{userDetails?.role ? USER_ROLE_LABELS[userDetails?.role] : ""}</small>
              </div>
              <i className='pi pi-angle-down'></i>
            </div>
          }
          align={"end"}
          id={`offcanvasNavbarDropdown-expand-lg}`}
        >
          <NavDropdown.Item onClick={() => navigate('/setting/profile')}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_513_54964)">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.9961 9.0037C8.9961 7.3448 10.3409 6 11.9998 6C13.6587 6 15.0035 7.3448 15.0035 9.0037C15.0035 10.6626 13.6587 12.0074 11.9998 12.0074C10.3409 12.0074 8.9961 10.6626 8.9961 9.0037ZM11.9998 7.06013C10.9264 7.06013 10.0562 7.9303 10.0562 9.0037C10.0562 10.0771 10.9264 10.9473 11.9998 10.9473C13.0732 10.9473 13.9434 10.0771 13.9434 9.0037C13.9434 7.9303 13.0732 7.06013 11.9998 7.06013Z" fill="#777777" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.17279 14.1277C8.29455 14.1277 7.58259 14.8396 7.58259 15.7179V16.5577C7.58259 16.5705 7.59186 16.5814 7.60448 16.5834C10.5154 17.0587 13.4842 17.0587 16.3951 16.5834C16.4077 16.5814 16.417 16.5705 16.417 16.5577V15.7179C16.417 14.8396 15.705 14.1277 14.8268 14.1277H14.5859C14.5673 14.1277 14.5488 14.1306 14.5311 14.1364L13.9193 14.3361C12.6721 14.7434 11.3275 14.7434 10.0802 14.3361L9.46854 14.1364C9.45083 14.1306 9.43232 14.1277 9.4137 14.1277H9.17279ZM6.52246 15.7179C6.52246 14.2541 7.70905 13.0675 9.17279 13.0675H9.4137C9.54409 13.0675 9.67366 13.0881 9.79761 13.1286L10.4093 13.3284C11.4428 13.6658 12.5568 13.6658 13.5903 13.3284L14.202 13.1286C14.3259 13.0881 14.4555 13.0675 14.5859 13.0675H14.8268C16.2905 13.0675 17.4771 14.2541 17.4771 15.7179V16.5577C17.4771 17.09 17.0913 17.5439 16.5659 17.6297C13.5419 18.1234 10.4577 18.1234 7.43366 17.6297C6.90827 17.5439 6.52246 17.09 6.52246 16.5577V15.7179Z" fill="#777777" />
              </g>
              <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#E0E0E0" />
              <defs>
                <clipPath id="clip0_513_54964">
                  <rect width="24" height="24" rx="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Profile
          </NavDropdown.Item>
          <NavDropdown.Item disabled={logOutMutation.isPending} onClick={() => logOutMutation.mutate()}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_513_54969)">
                <path d="M12.6667 6H11.3333V12.6667H12.6667V6ZM15.8867 7.44667L14.94 8.39333C15.9933 9.24 16.6667 10.54 16.6667 12C16.6667 14.58 14.58 16.6667 12 16.6667C9.42 16.6667 7.33333 14.58 7.33333 12C7.33333 10.54 8.00667 9.24 9.05333 8.38667L8.11333 7.44667C6.82 8.54667 6 10.1733 6 12C6 15.3133 8.68667 18 12 18C15.3133 18 18 15.3133 18 12C18 10.1733 17.18 8.54667 15.8867 7.44667Z" fill="#777777" />
              </g>
              <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#E0E0E0" />
              <defs>
                <clipPath id="clip0_513_54969">
                  <rect width="24" height="24" rx="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>

    </Navbar>
  )
}

export default Header