import { PERMISSION } from "../types/permission.type"
import { USER_ROLES } from "../types/user-roles.type"

export const ROLE_PERMISSIONS = {
    [USER_ROLES.MASTER_USER]: {
        permissions: [
            // Profile
            PERMISSION.VIEW_PROFILE,
            PERMISSION.EDIT_PROFILE,

            // Dashboard
            PERMISSION.VIEW_DASHBOARD,
            PERMISSION.VIEW_QUESTION_DISTRIBUTION_ANALYTICS,
            PERMISSION.VIEW_RESOURCE_LIST,

            // Questions
            PERMISSION.VIEW_QUESTIONS,
            PERMISSION.PREVIEW_QUESTIONS,
            PERMISSION.CREATE_QUESTIONS,
            PERMISSION.UPDATE_QUESTIONS,
            PERMISSION.DELETE_QUESTIONS,
            PERMISSION.IMPORT_QUESTIONS,
            PERMISSION.BULK_ACTION,
            PERMISSION.BULK_CHANGE_QUESTION_STATUS,
            PERMISSION.BULK_CHANGE_QUESTION_EXAM,
            PERMISSION.BULK_SEND_QUESTION_TO_USER,
            PERMISSION.BULK_EXPORT_QUESTIONS,
            PERMISSION.BULK_DELETE_QUESTIONS,
            PERMISSION.VIEW_CREATED_BY_COLUMN,
            PERMISSION.VIEW_LOG_COLUMN,
            PERMISSION.VIEW_REVIEWER_COLUMN,
            PERMISSION.VIEW_ASSIGNED_USER_COLUMN,
            PERMISSION.VIEW_STATUS_COLUMN,
            PERMISSION.VIEW_ACTION_COLUMN,
            PERMISSION.SINGLE_CHANGE_QUESTION_STATUS,
            PERMISSION.SINGLE_EXPORT_QUESTION,
            PERMISSION.SINGLE_SEND_USER_QUESTION,
            PERMISSION.VIEW_QUESTION_LOG,
            PERMISSION.VIEW_QUESTION_REVIEW,
            PERMISSION.ADD_QUESTION_REVIEW,
            PERMISSION.ADD_QUESTIONS_TRANSLATION,
            PERMISSION.VIEW_STATUS_FILTER,
            PERMISSION.REMOVE_QUESTIONS_TRANSLATION,
            PERMISSION.BULK_DOWNLOAD_QUESTION_LOGS,

            // Examinations
            PERMISSION.VIEW_EXAMINATIONS,
            PERMISSION.CREATE_EXAMINATIONS,
            PERMISSION.UPDATE_EXAMINATIONS,
            PERMISSION.DELETE_EXAMINATIONS,

            // Formats
            PERMISSION.VIEW_FORMATS,
            PERMISSION.CREATE_FORMATS,
            PERMISSION.UPDATE_FORMATS,
            PERMISSION.DELETE_FORMATS,

            // Users
            PERMISSION.VIEW_USERS,
            PERMISSION.CREATE_USERS,
            PERMISSION.UPDATE_USERS,
            PERMISSION.DELETE_USERS,

            // Subjects
            PERMISSION.VIEW_SUBJECTS,
            PERMISSION.CREATE_SUBJECTS,
            PERMISSION.UPDATE_SUBJECTS,
            PERMISSION.DELETE_SUBJECTS,

            // Topics
            PERMISSION.VIEW_TOPICS,
            PERMISSION.CREATE_TOPICS,
            PERMISSION.UPDATE_TOPICS,
            PERMISSION.DELETE_TOPICS,

            // Sub Topics
            PERMISSION.VIEW_SUB_TOPICS,
            PERMISSION.CREATE_SUB_TOPICS,
            PERMISSION.UPDATE_SUB_TOPICS,
            PERMISSION.DELETE_SUB_TOPICS,

            // Levels
            PERMISSION.VIEW_LEVELS,
            PERMISSION.CREATE_LEVELS,
            PERMISSION.UPDATE_LEVELS,
            PERMISSION.DELETE_LEVELS,

            // Difficulty Levels
            PERMISSION.VIEW_DIFFICULTY_LEVELS,
            PERMISSION.CREATE_DIFFICULTY_LEVELS,
            PERMISSION.UPDATE_DIFFICULTY_LEVELS,
            PERMISSION.DELETE_DIFFICULTY_LEVELS,

            //Question code 1 permissions
            PERMISSION.VIEW_QUESTION_CODE_1,
            PERMISSION.CREATE_QUESTION_CODE_1,
            PERMISSION.UPDATE_QUESTION_CODE_1,
            PERMISSION.DELETE_QUESTION_CODE_1,

            //Question code 2 permissions
            PERMISSION.VIEW_QUESTION_CODE_2,
            PERMISSION.CREATE_QUESTION_CODE_2,
            PERMISSION.UPDATE_QUESTION_CODE_2,
            PERMISSION.DELETE_QUESTION_CODE_2,

            //Language permissions
            PERMISSION.VIEW_LANGUAGE,
            PERMISSION.CREATE_LANGUAGE,
            PERMISSION.UPDATE_LANGUAGE,
            PERMISSION.DELETE_LANGUAGE,
        ],
        defaultRoute: '/dashboard',
    },
    [USER_ROLES.CONTENT_MANAGER]: {
        permissions: [
            // Profile
            PERMISSION.VIEW_PROFILE,
            PERMISSION.EDIT_PROFILE,

            // Dashboard
            PERMISSION.VIEW_DASHBOARD,
            PERMISSION.VIEW_QUESTION_DISTRIBUTION_ANALYTICS,

            // Question
            PERMISSION.VIEW_QUESTIONS,
            PERMISSION.PREVIEW_QUESTIONS,
            PERMISSION.CREATE_QUESTIONS,
            PERMISSION.UPDATE_QUESTIONS,
            PERMISSION.DELETE_QUESTIONS,
            PERMISSION.IMPORT_QUESTIONS,
            PERMISSION.BULK_ACTION,
            PERMISSION.BULK_CHANGE_QUESTION_STATUS,
            PERMISSION.BULK_CHANGE_QUESTION_EXAM,
            PERMISSION.BULK_SEND_QUESTION_TO_USER,
            PERMISSION.BULK_EXPORT_QUESTIONS,
            PERMISSION.BULK_DELETE_QUESTIONS,
            PERMISSION.VIEW_CREATED_BY_COLUMN,
            PERMISSION.VIEW_LOG_COLUMN,
            PERMISSION.VIEW_REVIEWER_COLUMN,
            PERMISSION.VIEW_ASSIGNED_USER_COLUMN,
            PERMISSION.VIEW_STATUS_COLUMN,
            PERMISSION.VIEW_ACTION_COLUMN,
            PERMISSION.SINGLE_CHANGE_QUESTION_STATUS,
            PERMISSION.SINGLE_EXPORT_QUESTION,
            PERMISSION.SINGLE_SEND_USER_QUESTION,
            PERMISSION.VIEW_QUESTION_LOG,
            PERMISSION.VIEW_QUESTION_REVIEW,
            PERMISSION.ADD_QUESTION_REVIEW,
            PERMISSION.ADD_QUESTIONS_TRANSLATION,
            PERMISSION.VIEW_STATUS_FILTER,
            PERMISSION.REMOVE_QUESTIONS_TRANSLATION,
        ],
        defaultRoute: '/dashboard',
    },
    [USER_ROLES.CREATOR]: {
        permissions: [
            // Profile
            PERMISSION.VIEW_PROFILE,

            // Questions
            PERMISSION.VIEW_QUESTIONS,
            PERMISSION.PREVIEW_QUESTIONS,
            PERMISSION.CREATE_QUESTIONS,
            PERMISSION.UPDATE_QUESTIONS,
            PERMISSION.IMPORT_QUESTIONS,
            PERMISSION.VIEW_STATUS_COLUMN,
            PERMISSION.VIEW_ACTION_COLUMN,
            PERMISSION.VIEW_QUESTION_REVIEW,
            PERMISSION.VIEW_STATUS_FILTER,
            PERMISSION.REMOVE_QUESTIONS_TRANSLATION,
        ],
        defaultRoute: '/questions',
    },
    [USER_ROLES.REVIEWER]: {
        permissions: [
            // Profile
            PERMISSION.VIEW_PROFILE,

            // Questions
            PERMISSION.VIEW_QUESTIONS,
            PERMISSION.PREVIEW_QUESTIONS,
            PERMISSION.VIEW_STATUS_COLUMN,
            PERMISSION.VIEW_ADD_REVIEW_COLUMN,
            PERMISSION.ADD_QUESTION_REVIEW,
            PERMISSION.VIEW_STATUS_FILTER,
            PERMISSION.VIEW_QUESTION_REVIEW,
        ],
        defaultRoute: '/questions',
    },
    [USER_ROLES.TRANSLATOR]: {
        permissions: [
            // Profile
            PERMISSION.VIEW_PROFILE,

            // Questions
            PERMISSION.VIEW_QUESTIONS,
            PERMISSION.PREVIEW_QUESTIONS,
            PERMISSION.VIEW_STATUS_COLUMN,
            PERMISSION.VIEW_STATUS_FILTER,
            PERMISSION.VIEW_ADD_TRANSLATION_COLUMN,
            PERMISSION.ADD_QUESTIONS_TRANSLATION,
        ],
        defaultRoute: '/questions',
    },
}

export const hasPermission = (role: USER_ROLES | undefined, permissions: PERMISSION | PERMISSION[], requireAll: boolean = true): boolean => {
    if (!role || !ROLE_PERMISSIONS[role]) return false;

    const rolePermissions = ROLE_PERMISSIONS[role].permissions;

    if (Array.isArray(permissions))
        return requireAll
            ? permissions.every((permission) => rolePermissions.includes(permission)) // All permissions required
            : permissions.some((permission) => rolePermissions.includes(permission)); // Any one permission required

    return rolePermissions.includes(permissions); // For single permission
}