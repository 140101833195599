export enum PERMISSION {
    // Go to fallback
    FALLBACK = "fallback",

    // Profile permissions
    VIEW_PROFILE = "view:profile",
    EDIT_PROFILE = "edit:profile",

    // Dashboard permission
    VIEW_DASHBOARD = "view:dashboard",
    VIEW_QUESTION_DISTRIBUTION_ANALYTICS = "view:question-distribution-analytics",
    VIEW_RESOURCE_LIST = "view:resource-list",

    // Question permissions
    VIEW_QUESTIONS = "view:questions",
    CREATE_QUESTIONS = "create:questions",
    UPDATE_QUESTIONS = "update:questions",
    DELETE_QUESTIONS = "delete:questions",
    IMPORT_QUESTIONS = "import-questions",
    PREVIEW_QUESTIONS = "preview:questions",

    BULK_ACTION = "bulk:action",
    BULK_CHANGE_QUESTION_STATUS = "bulk:change-question-status",
    BULK_CHANGE_QUESTION_EXAM = "bulk:change-question-exam",
    BULK_SEND_QUESTION_TO_USER = "bulk:send-questions-to-user",
    BULK_EXPORT_QUESTIONS = "bulk:export-questions",
    BULK_DELETE_QUESTIONS = "bulk:delete-questions",
    BULK_DOWNLOAD_QUESTION_LOGS = "bulk:download-question-logs",

    SINGLE_CHANGE_QUESTION_STATUS = "single:change-question-status",
    SINGLE_EXPORT_QUESTION = "single:export-question",
    SINGLE_SEND_USER_QUESTION = "single:send-user-question",

    VIEW_QID_COLUMN = "view:qid-column",
    VIEW_TYPE_COLUMN = "view:type-column",
    VIEW_EXAMINATION_COLUMN = "view:examination-column",
    VIEW_LANGUAGE_COLUMN = "view:language-column",
    VIEW_QUESTION_CODE_1_COLUMN = "view:question-code-1-column",
    VIEW_QUESTION_CODE_2_COLUMN = "view:question-code-2-column",
    VIEW_SUBJECT_COLUMN = "view:subject-column",
    VIEW_TOPIC_COLUMN = "view:topic-column",
    VIEW_SUB_TOPIC_COLUMN = "view:sub-topic-column",
    VIEW_CREATED_BY_COLUMN = "view:created-by-column",
    VIEW_LOG_COLUMN = "view:log-column",
    VIEW_REVIEWER_COLUMN = "view:reviewer-column",
    VIEW_ASSIGNED_USER_COLUMN = "view:assigned-user-column",
    VIEW_STATUS_COLUMN = "view:status-column",
    VIEW_UPDATED_AT_COLUMN = "view:updated-at-column",
    VIEW_ADD_REVIEW_COLUMN = "view:add-review-column",
    VIEW_ADD_TRANSLATION_COLUMN = "view:add-translation-column",
    VIEW_ACTION_COLUMN = "view:action-column",
    ADD_QUESTION_REVIEW = "add:question-review",
    ADD_QUESTIONS_TRANSLATION = "add:questions-translation",
    REMOVE_QUESTIONS_TRANSLATION = "remove:questions-translation",
    VIEW_QUESTION_LOG = "view:question-log",
    VIEW_QUESTION_REVIEW = "view:question-review",

    VIEW_STATUS_FILTER = "view:status-filter",

    // Examination permissions
    VIEW_EXAMINATIONS = "view:examinations",
    CREATE_EXAMINATIONS = "create:examinations",
    UPDATE_EXAMINATIONS = "update:examinations",
    DELETE_EXAMINATIONS = "delete:examinations",

    // User permissions
    VIEW_USERS = "view:users",
    CREATE_USERS = "create:users",
    UPDATE_USERS = "update:users",
    DELETE_USERS = "delete:users",

    // Subject permissions
    VIEW_SUBJECTS = "view:subjects",
    CREATE_SUBJECTS = "create:subjects",
    UPDATE_SUBJECTS = "update:subjects",
    DELETE_SUBJECTS = "delete:subjects",

    // Topic permissions
    VIEW_TOPICS = "view:topics",
    CREATE_TOPICS = "create:topics",
    UPDATE_TOPICS = "update:topics",
    DELETE_TOPICS = "delete:topics",

    // Sub-topic permissions
    VIEW_SUB_TOPICS = "view:sub-topics",
    CREATE_SUB_TOPICS = "create:sub-topics",
    UPDATE_SUB_TOPICS = "update:sub-topics",
    DELETE_SUB_TOPICS = "delete:sub-topics",

    // Level permissions
    VIEW_LEVELS = "view:levels",
    CREATE_LEVELS = "create:levels",
    UPDATE_LEVELS = "update:levels",
    DELETE_LEVELS = "delete:levels",

    // Difficulty Level permissions
    VIEW_DIFFICULTY_LEVELS = "view:difficulty-levels",
    CREATE_DIFFICULTY_LEVELS = "create:difficulty-levels",
    UPDATE_DIFFICULTY_LEVELS = "update:difficulty-levels",
    DELETE_DIFFICULTY_LEVELS = "delete:difficulty-levels",

    // Question code 1 permissions
    VIEW_QUESTION_CODE_1 = " view:question-code-1",
    CREATE_QUESTION_CODE_1 = "create:question-code-1",
    UPDATE_QUESTION_CODE_1 = "update:question-code-1",
    DELETE_QUESTION_CODE_1 = "delete:question-code-1",

    // Question code 2 permissions
    VIEW_QUESTION_CODE_2 = " view:question-code-2",
    CREATE_QUESTION_CODE_2 = "create:question-code-2",
    UPDATE_QUESTION_CODE_2 = "update:question-code-2",
    DELETE_QUESTION_CODE_2 = "delete:question-code-2",

    // Format permissions
    VIEW_FORMATS = "view:formats",
    CREATE_FORMATS = "create:formats",
    UPDATE_FORMATS = "update:formats",
    DELETE_FORMATS = "delete:formats",

    // Language permissions
    VIEW_LANGUAGE = "view:language",
    CREATE_LANGUAGE = "create:language",
    UPDATE_LANGUAGE = "update:language",
    DELETE_LANGUAGE = "delete:language",

    // Tag permissions
    VIEW_TAGS = "view:tags",
    CREATE_TAGS = "create:tags",
    UPDATE_TAGS = "update:tags",
    DELETE_TAGS = "delete:tags",
}