import { useEffect } from "react";

const useDisableRightClickAndDevTools = () => {
    useEffect(() => {
        const disableRightClick = (event: MouseEvent) => event.preventDefault();
        const disableDevTools = (event: KeyboardEvent) => {
            if (event.key === "F12" || (event.ctrlKey && event.shiftKey && event.key === "I")) {
                event.preventDefault();
            }
        };

        document.addEventListener("contextmenu", disableRightClick);
        document.addEventListener("keydown", disableDevTools);

        return () => {
            document.removeEventListener("contextmenu", disableRightClick);
            document.removeEventListener("keydown", disableDevTools);
        };
    }, []);
};

export default useDisableRightClickAndDevTools;
