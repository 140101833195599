import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@/app/providers/auth-provider';

const PublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { session } = useAuth();
  if (session) return <Navigate to="/" replace />;

  return <>{children}</>;
};

export default PublicRoute;