import { useEffect } from "react";

const useDisableScreenshots = () => {
    useEffect(() => {
        const disableScreenshot = (event: KeyboardEvent) => {
            if (event.key === "PrintScreen") {
                event.preventDefault();
                alert("Screenshots are disabled on this page.");
            }
        };

        document.addEventListener("keydown", disableScreenshot);
        return () => document.removeEventListener("keydown", disableScreenshot);
    }, []);
};

export default useDisableScreenshots;
