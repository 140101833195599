import { useEffect, useState } from "react";

const useScreenRecordingDetector = () => {
    const [isRecording, setIsRecording] = useState(false);

    useEffect(() => {
        const detectRecording = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const isScreenBeingRecorded = devices.some(
                    (device) => device.kind === "videoinput" && device.label.includes("Screen")
                );
                setIsRecording(isScreenBeingRecorded);
            } catch (error) {
                console.error("Error detecting screen recording:", error);
            }
        };

        detectRecording();
        const interval = setInterval(detectRecording, 3000);

        return () => clearInterval(interval);
    }, []);

    return isRecording;
};

export default useScreenRecordingDetector;
