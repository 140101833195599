import React, { createContext, useContext } from 'react';
import { session } from '@/shared/types/session.type';
import { useSessionQuery } from '@/entities/session/model/session.query';
import SessionExpired from '@/shared/ui/session-expired';

interface AuthContextType {
    session: session | null;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
    refetchSession: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data, error, isLoading, isError, refetch } = useSessionQuery();

    const value = { session: data || null, isLoading, isError, error: error || null, refetchSession: refetch };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
    
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};